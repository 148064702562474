import {
  Breadcrumbs,
  EventDate,
  LocationBlock,
  MultiLineText,
  PageLoading,
} from '@larpcalendar/web-ui';
import { HStack, Heading, Link, Stack, Tag, Text } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';

import { FunctionComponent } from 'react';
import Meta from '../meta/meta';
import { useEvent } from '@larpcalendar/firebase';

/* eslint-disable-next-line */
export interface EventDetailPageProps {}

export interface EventDetailPageParams {
  id: string;
}

export const EventDetailPage: FunctionComponent<EventDetailPageProps> = () => {
  const { id = '' } = useParams<EventDetailPageParams>();
  console.log('id', id);
  const { status, data } = useEvent(id);
  const { pathname } = useLocation();

  return status === 'loading' ? (
    <PageLoading testid="EventDetailPage" />
  ) : data ? (
    <Stack data-testid="EventDetailPage">
      <Meta title="Events" />
      <Breadcrumbs
        crumbs={[
          { label: 'Events', to: '/events' },
          { to: pathname, label: data.name || 'Event Detail Page' },
        ]}
      />
      <HStack>
        <Heading as="h1">{data.name}</Heading>
        {data.eventType && <Tag>{data.eventType}</Tag>}
      </HStack>
      <EventDate startDate={data.startDate} endDate={data.endDate} />
      {data.location && <LocationBlock location={data.location} />}
      {data.description && <MultiLineText>{data.description}</MultiLineText>}

      {data.url && (
        <Text>
          <Link href={data.url} textDecoration="underline" isExternal>
            {data.url}
          </Link>
        </Text>
      )}
    </Stack>
  ) : (
    <Stack data-testid="EventDetailPage">
      <Meta title="Event Not Found" />
      <Breadcrumbs
        crumbs={[
          { label: 'Events', to: '/events' },
          { to: pathname, label: 'Event Not Found' },
        ]}
      />
      <Heading as="h1">Event Not Found</Heading>
      <Text>We couldn't find the event you are looking for, sorry!</Text>
    </Stack>
  );
};

export default EventDetailPage;
