import { FunctionComponent, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Protected } from '../protected/protected';

const DashboardPage = lazy(() => import('../dashboard-page/dashboard-page'));

/* eslint-disable-next-line */
export interface DashboardRouterProps {}

export const DashboardRouter: FunctionComponent<DashboardRouterProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Protected>
      <Switch>
        <Route exact path={path}>
          <DashboardPage />
        </Route>
      </Switch>
    </Protected>
  );
};

export default DashboardRouter;
