import { Stack, Text } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Location } from '@larpcalendar/types';

export interface LocationBlockProps {
  location?: Location;
}

export const LocationBlock: FunctionComponent<LocationBlockProps> = ({
  location,
}) => {
  return location ? (
    <Text>
      {location.name && (
        <Text fontWeight="semibold" as="span">
          {location.name},
        </Text>
      )}
      <Text as="span">
        {location.address && `${location.address}, `}
        {location.postalCode} {location.city}
      </Text>

      {location.country && <Text as="span">{location.country}</Text>}
    </Text>
  ) : null;
};

export default LocationBlock;
