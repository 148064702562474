import {
  AlertBox,
  AlertBoxProps,
  FlashMessage,
  PageLoading,
  Section,
  SignInForm,
} from '@larpcalendar/web-ui';
import {
  Button,
  ButtonGroup,
  Container,
  HStack,
  Heading,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { Redirect, Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth, useSigninCheck } from 'reactfire';

import { FirebaseError } from '@firebase/util';
import { FormikHelpers } from 'formik';
import { Meta } from '../meta/meta';
import { SignInFormValues } from '@larpcalendar/types';
import { signInWithEmailAndPassword } from 'firebase/auth';

/* eslint-disable-next-line */
export interface SignInPageProps {}

const credentialErrors = ['auth/user-not-found', 'auth/wrong-password'];

export const SignInPage: FunctionComponent<SignInPageProps> = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [error, setError] = useState<Partial<AlertBoxProps> | null>(null);
  const firebaseAuth = useAuth();
  const toast = useToast();

  const onSubmit = async (
    values: SignInFormValues,
    { setSubmitting }: FormikHelpers<SignInFormValues>
  ) => {
    try {
      setError(null);
      setIsInvalid(false);
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        values.email,
        values.password
      );
      const description = userCredential?.user?.displayName
        ? `Welcome back ${userCredential.user.displayName}`
        : 'Welcome back!';
      toast({
        title: 'Signed in',
        description,
        status: 'success',
      });
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (credentialErrors.includes(error.code)) {
          setError({
            title: "Couldn't sign in",
            description: 'Email or password was incorrect!',
          });
          setIsInvalid(true);
        } else {
          setError({
            title: "Couldn't sign in",
            description: 'Please try again!',
          });
        }
      } else {
        setError({
          title: "Couldn't sign in",
          description: 'Please try again!',
        });
      }
      setSubmitting(false);
    }
  };

  return status === 'loading' ? (
    <PageLoading />
  ) : signInCheckResult.signedIn ? (
    <Redirect to="/" />
  ) : (
    <Container px={0}>
      <Meta title="Sign in" />
      <Stack spacing={6} data-testid="AuthPage">
        <FlashMessage />
        <Section id="signin" variant="bleed">
          <Container>
            <Heading as="h1">Sign in</Heading>
          </Container>
          <Stack alignItems="center" width="100%">
            <SignInForm onSubmit={onSubmit} isInvalid={isInvalid} />

            {error && <AlertBox {...error} center={false} />}
            <ButtonGroup variant="ghost" justifyContent="center">
              <Button
                as={RouterLink}
                to="/sign-in/forgot-password"
                fontSize="sm"
                textDecoration="underline"
                role="button"
              >
                Forgot password?
              </Button>
              <Button
                as={RouterLink}
                to="/sign-in/register"
                fontSize="sm"
                textDecoration="underline"
              >
                Register
              </Button>
            </ButtonGroup>
          </Stack>
        </Section>
      </Stack>
    </Container>
  );
};

export default SignInPage;
