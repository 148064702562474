import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbData {
  to: string;
  label: string;
}

export interface BreadcrumbsProps {
  crumbs?: BreadcrumbData[];
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  crumbs = [],
}) => {
  const crumbsMapper = (
    { label, to }: BreadcrumbData,
    index: number,
    array: BreadcrumbData[]
  ) => {
    return (
      <BreadcrumbItem
        isCurrentPage={index < array.length}
        key={`Breadcrumb-${index}`}
      >
        {to ? (
          <BreadcrumbLink as={Link} to={to}>
            {label}
          </BreadcrumbLink>
        ) : (
          <BreadcrumbLink
            _hover={{ textDecoration: 'none', cursor: 'inherit' }}
          >
            {label}
          </BreadcrumbLink>
        )}
      </BreadcrumbItem>
    );
  };
  return (
    <Breadcrumb fontSize="sm">
      <BreadcrumbItem isCurrentPage={!crumbs?.length}>
        <BreadcrumbLink as={Link} to="/">
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>
      {crumbs.map(crumbsMapper)}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
