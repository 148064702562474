import * as Yup from 'yup';

import {
  AlertBox,
  AlertBoxProps,
  FlashMessage,
  Form,
  FormField,
  Section,
  Submit,
} from '@larpcalendar/web-ui';
import {
  Button,
  ButtonGroup,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent, useState } from 'react';

import { FaArrowRight } from 'react-icons/fa';
import { ForgotPasswordFormValues } from '@larpcalendar/types';
import { Meta } from '../meta/meta';
import { Link as RouterLink } from 'react-router-dom';
import { sendPasswordResetEmail } from '@firebase/auth';
import { useAuth } from 'reactfire';

/* eslint-disable-next-line */
export interface ForgotPasswordPageProps {}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('You need to enter a valid email address')
    .required('You need to enter an email address'),
});

export const ForgotPasswordPage: FunctionComponent<ForgotPasswordPageProps> =
  () => {
    const [error, setError] = useState<Partial<AlertBoxProps> | null>(null);
    const [done, setDone] = useState<boolean>(false);
    const firebaseAuth = useAuth();

    const onSubmit = async (
      values: ForgotPasswordFormValues,
      { setSubmitting }: FormikHelpers<ForgotPasswordFormValues>
    ) => {
      try {
        setError(null);
        await sendPasswordResetEmail(firebaseAuth, values.email);
        setDone(true);
      } catch (error) {
        setSubmitting(false);
        console.error(error);
      }
    };

    const {
      handleSubmit,
      handleBlur,
      handleChange,
      isSubmitting,
      isValid,
      errors,
      values,
      dirty,
    } = useFormik<ForgotPasswordFormValues>({
      initialValues: { email: '' },
      validationSchema,
      onSubmit,
    });

    return done ? (
      <Container px={0}>
        <Meta title="Forgot Password" />
        <Stack spacing={6} data-testid="AuthPage">
          <Section id="forgotPassword">
            <Heading as="h1">Forgot Password</Heading>
            <Text>
              An email has been sent to you with instructions how to set a new a
              password.
            </Text>
            <Text>It should arrive shortly!</Text>
          </Section>
        </Stack>
      </Container>
    ) : (
      <Container px={0}>
        <Meta title="Forgot Password" />
        <Stack spacing={6} data-testid="AuthPage">
          <FlashMessage />
          <Section id="forgotPassword">
            <Heading as="h1">Forgot Password</Heading>
            <Stack alignItems="center" width="100%">
              <Form onSubmit={handleSubmit}>
                <FormField
                  label="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  error={errors.email}
                  isRequired
                />
                <Submit isLoading={isSubmitting} rightIcon={<FaArrowRight />}>
                  Reset Password
                </Submit>
              </Form>
              {error && <AlertBox {...error} center={false} />}
              <ButtonGroup variant="ghost" justifyContent="center">
                <Button
                  as={RouterLink}
                  to="/sign-in"
                  fontSize="sm"
                  textDecoration="underline"
                  role="button"
                >
                  Sign in
                </Button>
                <Button
                  as={RouterLink}
                  to="/sign-in/register"
                  fontSize="sm"
                  textDecoration="underline"
                >
                  Register
                </Button>
              </ButtonGroup>
            </Stack>
          </Section>
        </Stack>
      </Container>
    );
  };

export default ForgotPasswordPage;
