import { Box, LinkBox, LinkOverlay, Text, TextProps } from '@chakra-ui/react';

import Card from '../card/card';
import CardContent from '../card-content/card-content';
import CardHeader from '../card-header/card-header';
import DetailsAction from '../details-action/details-action';
import { FunctionComponent } from 'react';
import { MultiLineText } from '../multi-line-text/multi-line-text';
import { Organizer } from '@larpcalendar/types';
import { Link as RouterLink } from 'react-router-dom';

/* eslint-disable-next-line */
export interface OrganizerCardProps {
  organizer: Organizer;
}

export const OrganizerCard: FunctionComponent<OrganizerCardProps> = ({
  organizer: { id, name, description },
}) => {
  if (!name) {
    return null;
  }

  return (
    <LinkBox as="article" data-testid="OrganizerCard">
      <Card mx="auto">
        <CardHeader
          title={name}
          action={<DetailsAction to={`/organizers/${id}`} />}
        />
        <CardContent>
          {!!description?.length && (
            <Box p={5}>
              <MultiLineText>{description}</MultiLineText>
            </Box>
          )}
        </CardContent>
      </Card>
    </LinkBox>
  );
};

export default OrganizerCard;
