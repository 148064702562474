import { FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import {
  LarpCalendarFormProps,
  RegisterOrganizerFormValues,
} from '@larpcalendar/types';

import { FaArrowRight } from 'react-icons/fa';
import { Form } from '../form/form';
import { FunctionComponent } from 'react';
import { Submit } from '../submit/submit';
import { useFormik } from 'formik';

export type RegisterOrganizerFormProps =
  LarpCalendarFormProps<RegisterOrganizerFormValues>;

export const RegisterOrganizerForm: FunctionComponent<RegisterOrganizerFormProps> =
  ({ children, onSubmit }) => {
    const {
      handleSubmit,
      handleBlur,
      handleChange,
      isSubmitting,
      values,
      errors,
    } = useFormik<RegisterOrganizerFormValues>({
      initialValues: { name: '', description: '', email: '', url: '' },
      onSubmit,
    });
    return (
      <Form onSubmit={handleSubmit}>
        <FormControl id="name" isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input
            name="name"
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            required
          />
        </FormControl>
        <FormControl id="description" isInvalid={!!errors.description}>
          <FormLabel>Description</FormLabel>
          <Textarea
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            required
          />
        </FormControl>
        <FormControl id="email" isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            type="email"
            inputMode="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            required
          />
        </FormControl>
        <FormControl id="url" isInvalid={!!errors.url}>
          <FormLabel>Website</FormLabel>
          <Input
            name="url"
            type="text"
            inputMode="url"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.url}
            required
          />
        </FormControl>
        <Submit isLoading={isSubmitting} rightIcon={<FaArrowRight />}>
          Submit
        </Submit>
        {children}
      </Form>
    );
  };

export default RegisterOrganizerForm;
