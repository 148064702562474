import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import { Organizer } from '@larpcalendar/types';

export const organizerConverter: FirestoreDataConverter<Organizer> = {
  toFirestore: (event: WithFieldValue<Organizer>): DocumentData => {
    return {
      name: event.name,
      description: event.description,
      url: event.url,
      email: event.email,
      location: event.location && {
        ...event.location,
      },
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): Organizer => {
    const data = snapshot.data(options);

    return {
      id: data.id,
      name: data.name,
      description: data.description || '',
      url: data.url || '',
      email: data.email || '',
      location: data.location && {
        name: data.location.name || '',
        address: data.location.address || '',
        postalCode: data.location.postalCode || '',
        city: data.location.city || '',
        country: data.location.country || '',
        coordinates: data.location.coordinates || undefined,
      },
    };
  },
};
