import * as Yup from 'yup';

import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from '@firebase/auth';
import { FormikHelpers, useFormik } from 'formik';

import { FaArrowRight } from 'react-icons/fa';
import { FirebaseError } from '@firebase/util';
import { Form } from '../form/form';
import { FormField } from '../form-field/form-field';
import { FunctionComponent } from 'react';
import { SettingsItem } from '../settings-item/settings-item';
import { Submit } from '../submit/submit';
import { useToast } from '@chakra-ui/react';
import { useUser } from 'reactfire';

/* eslint-disable-next-line */
export interface SettingsPasswordProps {}

export const SettingsPassword: FunctionComponent<SettingsPasswordProps> =
  () => {
    const { status, data: user } = useUser();
    const toast = useToast();

    interface SettingsPasswordValues {
      currentPassword: string;
      newPassword: string;
      confirmNewPassword: string;
    }

    const onSubmit = async (
      { currentPassword, newPassword }: SettingsPasswordValues,
      {
        setSubmitting,
        resetForm,
        setFieldError,
        setStatus,
      }: FormikHelpers<SettingsPasswordValues>
    ) => {
      try {
        if (!user) {
          throw new Error('User not found');
        }
        if (!user.email) {
          throw new Error('User email not found');
        }
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        resetForm({ values: initialValues });
        setSubmitting(false);
        setStatus('success');
        toast({
          title: 'Password updated',
          description: 'Password succesfully updated',
          status: 'success',
        });
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === 'auth/wrong-password'
        ) {
          setFieldError('currentPassword', 'Incorrect password!');
        } else {
          console.error(error);
        }
        setSubmitting(false);
      }
    };
    const validationSchema = Yup.object().shape({
      currentPassword: Yup.string()
        .trim()
        .required('You need to enter your current password'),
      newPassword: Yup.string()
        .trim()
        .when('currentPassword', {
          is: (value: string) => !!value?.length,
          then: Yup.string().notOneOf(
            [Yup.ref('currentPassword')],
            "New password can't be same as your current password"
          ),
        })
        .min(6, 'Your new password needs to be at least six characters long')
        .required('You need to enter a new password'),
      confirmNewPassword: Yup.string()
        .trim()
        .when('newPassword', {
          is: (value: string) => !!value?.length,
          then: Yup.string().oneOf(
            [Yup.ref('newPassword')],
            'Both passwords need to be the same'
          ),
        }),
    });

    const initialValues: SettingsPasswordValues = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };

    const {
      handleSubmit,
      isValid,
      isSubmitting,
      values,
      errors,
      handleBlur,
      handleChange,
    } = useFormik<SettingsPasswordValues>({
      onSubmit,
      validationSchema,
      initialValues,
    });
    return (
      <SettingsItem label="Password" status={status}>
        <Form onSubmit={handleSubmit}>
          <FormField
            label="Current Password"
            name="currentPassword"
            value={values.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={isValid}
            isSubmitting={isSubmitting}
            error={errors.currentPassword}
            type="password"
            isRequired
          />
          <FormField
            label="New Password"
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={isValid}
            isSubmitting={isSubmitting}
            error={errors.newPassword}
            type="password"
            isRequired
          />
          <FormField
            label="Confirm New Password"
            name="confirmNewPassword"
            value={values.confirmNewPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={isValid}
            isSubmitting={isSubmitting}
            error={errors.confirmNewPassword}
            type="password"
            isRequired
          />
          <Submit isLoading={isSubmitting} rightIcon={<FaArrowRight />}>
            Save
          </Submit>
        </Form>
      </SettingsItem>
    );
  };

export default SettingsPassword;
