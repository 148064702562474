import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

import { LarpEvent } from '@larpcalendar/types';

export const eventConverter: FirestoreDataConverter<LarpEvent> = {
  toFirestore: (event: LarpEvent): DocumentData => {
    return {
      name: event.name,
      organizer: { ...event.organizer },
      description: event.description || undefined,
      snippet: event.snippet || undefined,
      image: event.image || undefined,
      url: event.url || undefined,
      startDate: event.startDate,
      endDate: event.endDate || undefined,
      previousStartDate: event.previousStartDate || undefined,
      attendanceMode: event.attendanceMode || 'offline',
      eventStatus: event.eventStatus || 'scheduled',
      eventType: event.description || 'larp',
      capacity: event.capacity || undefined,
      location: event.location && {
        ...event.location,
      },
      tickets: event.tickets || undefined,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): LarpEvent => {
    const data = snapshot.data(options);
    return {
      id: data.id,
      name: data.name,
      organizer: data.organizer && {
        id: data.organizer.id,
        name: data.organizer.name,
      },
      description: data.description || '',
      snippet: data.snippet || '',
      image: data.image || '',
      url: data.url || '',
      startDate: data.startDate.toDate(),
      endDate: data.endDate && data.endDate?.toDate(),
      previousStartDate:
        data.previousStartDate && data.previousStartDate.toDate(),
      attendanceMode: data.attendanceMode || 'offline',
      eventStatus: data.eventStatus || 'scheduled',
      eventType: data.eventType || 'larp',
      capacity: data.capacity || undefined,
      location: data.location && {
        name: data.location.name || '',
        address: data.location.address || '',
        postalCode: data.location.postalCode || '',
        city: data.location.city || '',
        country: data.location.country || '',
        coordinates: data.location.coordinates || undefined,
      },
      tickets: data.tickets && [...data.tickets],
    };
  },
};
