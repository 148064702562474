import { FunctionComponent, lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { PageLoading } from '@larpcalendar/web-ui';
import { useSigninCheck } from 'reactfire';

const SignInPage = lazy(() => import('../sign-in-page/sign-in-page'));
const RegisterPage = lazy(() => import('../register-page/register-page'));
const ForgotPasswordPage = lazy(
  () => import('../forgot-password-page/forgot-password-page')
);

/* eslint-disable-next-line */
export interface AuthRouterProps {}

export const AuthRouter: FunctionComponent<AuthRouterProps> = () => {
  const { path } = useRouteMatch();
  const { status, data } = useSigninCheck();

  return status === 'loading' ? (
    <PageLoading />
  ) : !data?.signedIn ? (
    <Switch>
      <Route exact path={path}>
        <SignInPage />
      </Route>
      <Route path={`${path}/register`}>
        <RegisterPage />
      </Route>
      <Route path={`${path}/forgot-password`}>
        <ForgotPasswordPage />
      </Route>
      <Route path={`${path}/forgot-password`}>
        <ForgotPasswordPage />
      </Route>
    </Switch>
  ) : (
    <Redirect to="/" />
  );
};

export default AuthRouter;
