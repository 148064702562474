import { FunctionComponent } from 'react';
import { UnorderedList } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface BulletListProps {}

export const BulletList: FunctionComponent = ({ children }) => {
  return (
    <UnorderedList spacing={0.5} pl={5}>
      {children}
    </UnorderedList>
  );
};

export default BulletList;
