import {
  ObservableStatus,
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import { collection, query } from 'firebase/firestore';

import { LarpEvent } from '@larpcalendar/types';
import { eventConverter } from '../event-converter/event-converter';
import { orderBy } from '@firebase/firestore';
import { useState } from 'react';

export const useEvents = (): ObservableStatus<LarpEvent[]> => {
  const [isAscending] = useState<boolean>(true);
  const firestore = useFirestore();
  const collectionReference = collection(firestore, 'events').withConverter(
    eventConverter
  );
  const collectionQuery = query(
    collectionReference,
    orderBy('startDate', isAscending ? 'asc' : 'desc')
  );

  return useFirestoreCollectionData(collectionQuery, {
    idField: 'id',
  });
};

export default useEvents;
