import { Button, ButtonProps, Center } from '@chakra-ui/react';

import { FunctionComponent } from 'react';

export const Submit: FunctionComponent<ButtonProps> = ({
  isLoading,
  disabled,
  children,
  ...rest
}) => {
  return (
    <Button
      type="submit"
      isLoading={isLoading}
      disabled={disabled}
      colorScheme="blue"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default Submit;
