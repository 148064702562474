import {
  AdminRouter,
  AuthRouter,
  DashboardRouter,
  EventsRouter,
  OrganizersRouter,
  SettingsRouter,
} from '@larpcalendar/web-pages';
import { Body, PageLoading, theme } from '@larpcalendar/web-ui';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Center, ChakraProvider, Spinner } from '@chakra-ui/react';
import { FunctionComponent, Suspense, lazy, memo } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { PageViewLogger } from '@larpcalendar/firebase';
import { ScrollToTop } from '@larpcalendar/web-shared';

const AuthAction = lazy(() => import('./lazy/auth-action'));
const AboutPage = lazy(() => import('./lazy/about-page'));
const ContactPage = lazy(() => import('./lazy/contact-page'));
const EventsPage = lazy(() => import('./lazy/events-page'));
const PrivacyPolicyPage = lazy(() => import('./lazy/privacy-policy-page'));
const TermsOfServicePage = lazy(() => import('./lazy/terms-of-service-page'));
const RegisterOrganizerPage = lazy(
  () => import('./lazy/register-organizer-page')
);
const Header = lazy(() => import('./lazy/header'));
const Footer = lazy(() => import('./lazy/footer'));

export const App: FunctionComponent = memo(() => {
  // @TODO make this work in jest
  // useInitPerformance(async (firebaseApp) => {
  //   const { getPerformance } = await import('firebase/performance');
  //   return getPerformance(firebaseApp);
  // });

  const Fallback = () => (
    <Center
      mx="auto"
      maxW="7xl"
      py={{ base: '2', md: '4' }}
      px={{ base: '4', md: '8' }}
    >
      <Spinner />
    </Center>
  );

  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense fallback={<Fallback />}>
            <Header title="Larp Calendar" />
          </Suspense>
          <Suspense fallback={<PageLoading />}>
            <Body>
              <Switch>
                <Route path="/__/auth/action">
                  <AuthAction />
                </Route>
                <Route path="/" exact>
                  <EventsPage />
                </Route>
                <Route path="/events">
                  <EventsRouter />
                </Route>
                <Route path="/organizers">
                  <OrganizersRouter />
                </Route>
                <Route path="/about" exact>
                  <AboutPage />
                </Route>
                <Route path="/sign-in">
                  <AuthRouter />
                </Route>
                <Route path="/contact" exact>
                  <ContactPage />
                </Route>
                <Route path="/terms-of-service" exact>
                  <TermsOfServicePage />
                </Route>
                <Route path="/privacy-policy" exact>
                  <PrivacyPolicyPage />
                </Route>
                <Route path="/register-organizer" exact>
                  <RegisterOrganizerPage />
                </Route>
                <Route path="/dashboard">
                  <DashboardRouter />
                </Route>
                <Route path="/settings">
                  <SettingsRouter />
                </Route>
                <Route path="/admin">
                  <AdminRouter />
                </Route>
              </Switch>
            </Body>
          </Suspense>
          <Suspense fallback={<Fallback />}>
            <Footer />
          </Suspense>
          <PageViewLogger />
        </BrowserRouter>
      </ChakraProvider>
    </HelmetProvider>
  );
});

export default App;
