import { Container, Heading, Link, Stack, Text } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { Section } from '@larpcalendar/web-ui';

/* eslint-disable-next-line */
export interface ContactPageProps {}

export const ContactPage: FunctionComponent<ContactPageProps> = (_props) => {
  return (
    <Container px={0}>
      <Meta title="Contact" />
      <Stack>
        <Section>
          <Heading as="h1">Contact Larp Calendar</Heading>
          <Text>
            If you need to reach us for any reason, please contact us via email:
          </Text>
          <Text>
            <Link
              href="mailto:contact@larpcalendar.org"
              textDecoration="underline"
            >
              contact@larpcalendar.org
            </Link>
          </Text>
        </Section>
      </Stack>
    </Container>
  );
};

export default ContactPage;
