import { Stack, StackProps } from '@chakra-ui/react';

import { FunctionComponent } from 'react';

export const SettingsStack: FunctionComponent<StackProps> = ({
  children,
  ...rest
}) => {
  return (
    <Stack
      width="100%"
      direction={{ base: 'column', md: 'row' }}
      spacing={5}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default SettingsStack;
