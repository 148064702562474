/* eslint-disable-next-line */

import { Center, Spinner } from '@chakra-ui/react';

import { FunctionComponent } from 'react';

export interface PageLoadingProps {
  testid?: string;
}

export const PageLoading: FunctionComponent<PageLoadingProps> = ({
  testid,
}) => {
  return (
    <Center flex="1" data-testid={testid}>
      <Spinner />
    </Center>
  );
};

export default PageLoading;
