import {
  Container,
  Heading,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { Link as RouterLink } from 'react-router-dom';
import { Section } from '@larpcalendar/web-ui';

/* eslint-disable-next-line */
export interface TermsOfServicePageProps {}

export const TermsOfServicePage: FunctionComponent<TermsOfServicePageProps> = (
  _props
) => {
  return (
    <Container px={0}>
      <Meta title="Terms of Service" />
      <Stack spacing={10}>
        <Section>
          <Heading as="h1">Terms of Service</Heading>
          <Text>
            By using Larp Calender, you agree to these terms. Larp Calendar
            keeps its terms of service under regular review and places any
            updates on this web page. These terms of service were last updated
            on <time dateTime="2021-10-25">25 October 2021</time>.
          </Text>
          <Text>
            The purpose of Larp Calendar is for individuals to find larp events
            targeted at international audiences, and for organizers to publish
            information about such events.
          </Text>
          <Text>
            Users are only allowed to post events as representatives for the
            organizers of the event. If you find that your event has been posted
            on Larp Calendar by a third party without your consent, please{' '}
            <Link as={RouterLink} to="/contact" textDecoration="underline">
              contact Larp Calendar
            </Link>{' '}
            to claim ownership of the event.
          </Text>
          <Text>
            By publishing content on Larp Calendar you assure that you have the
            right to publish that content and accept legal liability for any
            intellectual property infringement.
          </Text>
          <Text>
            Larp Calendar accepts no responsibility for the intellectual
            property rights of content published by users. We will forward any
            legal claims on intellectual property to the users responsible for
            publishing it.
          </Text>
          <Text>
            You are not allowed to sell or in other ways monetize content from
            this website unless you are the original owner of content or in
            other ways have legal rights to the content.
          </Text>
          <Text>
            Larp Calendar accepts submissions of larps with all kinds of themes
            and settings. While tough subjects are welcome in the fiction of
            games we do not accept any out of game content that is derogatory
            towards vulnerable minorities. Larp Calendar is staunchly
            anti-fascist, feminist, pro LGBTQ rights and strive to keep this
            page a kind and inclusive place.
          </Text>
          <Text>
            Larp Calendar is not a place for bullying, vendettas or personal
            attacks. We do not accept content meant to attack, harass or
            belittle members of larp communities.
          </Text>
          <Text>
            You are responsible for all your actions on Larp Calendar. We can
            terminate your account if you breach these terms or we see fit to do
            so for any other reason.
          </Text>
          <Text>
            We can revoke service or access at any time. You must ask us and get
            permission before using or accessing data in ways not clearly
            intended through the website. E.g. scraping, reverse-engineering,
            copying, or redistributing data.
          </Text>
          <Text>
            Larp Calendar is a self-funded enthusiast project, not a staffed for
            profit business. Any functionality or service is provided as is and
            we offer no warranty or liability.
          </Text>
        </Section>
      </Stack>
    </Container>
  );
};

export default TermsOfServicePage;
