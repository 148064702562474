import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from 'reactfire';
import { FunctionComponent, memo } from 'react';
import { connectAuthEmulator, getAuth } from '@firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

import { FirebaseOptions } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.NX_FIREBASE_API_KEY,
  authDomain: process.env.NX_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NX_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NX_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NX_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NX_FIREBASE_APP_ID,
  measurementId: process.env.NX_FIREBASE_MEASUREMENT_ID,
};

const FirebaseBaseServicesProvider: FunctionComponent = memo(({ children }) => {
  const app = useFirebaseApp();
  const firestoreSdk = getFirestore(app);
  const authSdk = getAuth(app);
  const functionsSdk = getFunctions(app);

  if (process.env.NODE_ENV !== 'production') {
    connectFirestoreEmulator(firestoreSdk, 'localhost', 8080);
    connectAuthEmulator(authSdk, 'http://localhost:9099');
  }

  return (
    <FirestoreProvider sdk={firestoreSdk}>
      <FunctionsProvider sdk={functionsSdk}>
        <AuthProvider sdk={authSdk}>{children}</AuthProvider>
      </FunctionsProvider>
    </FirestoreProvider>
  );
});

const FirebaseServicesProvider: FunctionComponent = memo(({ children }) => {
  const analyticsSdk = getAnalytics(useFirebaseApp());

  return (
    <AnalyticsProvider sdk={analyticsSdk}>
      <FirebaseBaseServicesProvider>{children}</FirebaseBaseServicesProvider>
    </AnalyticsProvider>
  );
});

export const FirebaseProvider: FunctionComponent = memo(({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseServicesProvider>{children}</FirebaseServicesProvider>
    </FirebaseAppProvider>
  );
});

export const FirebaseTestProvider: FunctionComponent = memo(({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseBaseServicesProvider>{children}</FirebaseBaseServicesProvider>
    </FirebaseAppProvider>
  );
});

export default FirebaseProvider;
