import { FunctionComponent, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Protected } from '../protected/protected';

const SettingsPage = lazy(() => import('../settings-page/settings-page'));

/* eslint-disable-next-line */
export interface SettingsRouterProps {}

export const SettingsRouter: FunctionComponent<SettingsRouterProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Protected>
      <Switch>
        <Route exact path={path}>
          <SettingsPage />
        </Route>
      </Switch>
    </Protected>
  );
};

export default SettingsRouter;
