import { FunctionComponent } from 'react';
import { LinkOverlay } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

/* eslint-disable-next-line */
export interface DetailsActionProps {
  to: string;
  label?: string;
}

export const DetailsAction: FunctionComponent<DetailsActionProps> = ({
  to,
  label = 'View details',
}) => {
  return (
    <LinkOverlay
      as={RouterLink}
      to={to}
      p={5}
      d="block"
      textAlign="right"
      data-testid="DetailsAction"
    >
      {label}
    </LinkOverlay>
  );
};

export default DetailsAction;
