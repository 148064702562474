import { FunctionComponent, useEffect } from 'react';

import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';
import { useLocation } from 'react-router-dom';

export const PageViewLogger: FunctionComponent = () => {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: `${location.pathname}${location.search}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return null;
};
