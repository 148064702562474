import { BulletList, Section } from '@larpcalendar/web-ui';
import {
  Container,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { useLocation } from 'react-router-dom';

/* eslint-disable-next-line */
export interface PrivacyPolicyPageProps {}

export const PrivacyPolicyPage: FunctionComponent<PrivacyPolicyPageProps> = (
  _props
) => {
  const location = useLocation();

  return (
    <Container px={0}>
      <Meta title="Privacy Policy" />
      <Stack spacing={10}>
        <Section id="intro">
          <Heading as="h1">Privacy Policy</Heading>
          <Text>
            <em>Larp Calendar</em> is part of{' '}
            <Link href="https://nordiclarp.org/" isExternal>
              Nordic Larp
            </Link>
            . This privacy policy will explain how our organization uses the
            personal data we collect from you when you use our website.
          </Text>
        </Section>
        <Section id="topics">
          <Heading fontSize="2xl">Topics</Heading>
          <BulletList>
            <ListItem>
              <Link
                href={`${location.pathname}#what-data-do-we-collect`}
                textDecoration="underline"
              >
                What data do we collect?
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#how-do-we-collect-your-data`}
                textDecoration="underline"
              >
                How do we collect your data?
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#how-will-we-use-your-data`}
                textDecoration="underline"
              >
                How will we use your data?
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#how-do-we-store-your-data`}
                textDecoration="underline"
              >
                How do we store your data?
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#what-are-your-data-protection-rights`}
                textDecoration="underline"
              >
                What are your data protection rights?
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#privacy-policies-of-other-websites`}
                textDecoration="underline"
              >
                Privacy policies of other websites
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#changes-to-our-privacy-policy`}
                textDecoration="underline"
              >
                Changes to our privacy policy
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#how-to-contact-us`}
                textDecoration="underline"
              >
                How to contact us
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href={`${location.pathname}#how-to-contact-the-appropriate-authorities`}
                textDecoration="underline"
              >
                How to contact the appropriate authorities
              </Link>
            </ListItem>
          </BulletList>
        </Section>
        <Section id="what-data-do-we-collect">
          <Heading fontSize="2xl">What data do we collect?</Heading>
          <Text>Larp Calendar collects the following data:</Text>
          <Text>
            Personal identification information (email address, name if given,
            picture if given).
          </Text>
        </Section>
        <Section id="how-do-we-collect-your-data">
          <Heading fontSize="2xl">How do we collect your data?</Heading>
          <Text>
            You directly provide Larp Calendar with most of the data we collect.
            We collect data and process data when you:
          </Text>
          <BulletList>
            <ListItem>
              Register a user account, create an organizer profile or add an
              event.
            </ListItem>
            <ListItem>
              Voluntarily complete a survey or provide feedback via email.
            </ListItem>
            {/* <ListItem>
              Use or view our website via your browser’s cookies.
            </ListItem> */}
          </BulletList>
          {/* <Text>
            Larp Calendar may also receive your data indirectly from the
            following sources:
          </Text>
          <BulletList>
            <ListItem></ListItem>
          </BulletList> */}
        </Section>
        <Section id="how-will-we-use-your-data">
          <Heading fontSize="2xl">How will we use your data?</Heading>
          <Text>Larp Calendar collects your data so that we can:</Text>
          <BulletList>
            <ListItem>Manage your account.</ListItem>
            <ListItem>
              Email you with information about your account, organizer profile
              or events.
            </ListItem>
          </BulletList>
          <Text>
            Larp Calendar will never intentionally share your information with
            any third parties.
          </Text>
          <Text>
            Larp Calendar tracks visitor data for statistics. This data is not
            correlated to users personal data and we do not identify how
            individual visitors use the website.
          </Text>
        </Section>
        <Section id="how-do-we-store-your-data">
          <Heading fontSize="2xl">How do we store your data?</Heading>
          <Text>
            Larp Calendar uses Google Firebase to store and process data. You
            can read their Privacy and Security documentation for details on how
            long data is retained:{' '}
            <Link
              href="https://firebase.google.com/support/privacy"
              isExternal
              textDecoration="underline"
            >
              https://firebase.google.com/support/privacy
            </Link>
          </Text>
        </Section>
        <Section id="what-are-your-data-protection-rights">
          <Heading fontSize="2xl">
            What are your data protection rights?
          </Heading>
          <Text>
            Larp Calendar would like to make sure you are fully aware of all of
            your data protection rights. Every user is entitled to the
            following:
          </Text>
          <Text>
            <strong>The right to access</strong> – You have the right to request
            Larp Calendar for copies of your personal data. We may charge you a
            small fee for this service.
          </Text>
          <Text>
            <strong>The right to rectification</strong> – You have the right to
            request that Larp Calendar correct any information you believe is
            inaccurate. You also have the right to request Larp Calendar to
            complete the information you believe is incomplete.
          </Text>
          <Text>
            <strong>The right to erasure</strong> – You have the right to
            request that Larp Calendar erase your personal data, under certain
            conditions.
          </Text>
          <Text>
            <strong>The right to restrict processing</strong> – You have the
            right to request that Larp Calendar restrict the processing of your
            personal data, under certain conditions.
          </Text>
          <Text>
            <strong>The right to object to processing</strong> – You have the
            right to object to Larp Calendar’s processing of your personal data,
            under certain conditions.
          </Text>
          <Text>
            <strong>The right to data portability</strong> – You have the right
            to request that Larp Calendar transfer the data that we have
            collected to another organization, or directly to you, under certain
            conditions.
          </Text>
          <Text>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us at our
            email:{' '}
            <Link
              href="mailto:contact@larpcalendar.org"
              textDecoration="underline"
            >
              contact@larpcalendar.org
            </Link>
          </Text>
        </Section>
        <Section id="privacy-policies-of-other-websites">
          <Heading fontSize="2xl">Privacy policies of other websites</Heading>
          <Text>
            The Larp Calendar website contains links to other websites. Our
            privacy policy applies only to our website, so if you click on a
            link to another website, you should read their privacy policy.
          </Text>
        </Section>
        <Section id="changes-to-our-privacy-policy">
          <Heading fontSize="2xl">Changes to our privacy policy</Heading>
          <Text>
            Larp Calendar keeps its privacy policy under regular review and
            places any updates on this web page. This privacy policy was last
            updated on <time dateTime="2021-10-17">17 October 2021</time>.
          </Text>
        </Section>
        <Section id="how-to-contact-us">
          <Heading fontSize="2xl">How to contact us</Heading>
          <Text>
            If you have any questions about Larp Calendar’s privacy policy, the
            data we hold on you, or you would like to exercise one of your data
            protection rights, please do not hesitate to contact us.
          </Text>
          <Text>
            Email us at:{' '}
            <Link
              href="mailto:contact@larpcalendar.org"
              textDecoration="underline"
            >
              contact@larpcalendar.org
            </Link>
          </Text>
        </Section>
        <Section id="how-to-contact-the-appropriate-authorities">
          <Heading fontSize="2xl">
            How to contact the appropriate authorities
          </Heading>
          <Text>
            Should you wish to report a complaint or if you feel that Larp
            Calendar has not addressed your concern in a satisfactory manner,
            you may contact the{' '}
            <Link
              href="https://www.imy.se/en/about-us/contact-us/"
              isExternal
              textDecoration="underline"
            >
              Swedish Authority for Privacy Protection (IMY)
            </Link>
            .
          </Text>
          <Text>
            Email:{' '}
            <Link href="mailto:imy@imy.se" textDecoration="underline">
              imy@imy.se
            </Link>
          </Text>
          <Text>
            Phone:{' '}
            <Link href="tel:+4686576100" isExternal>
              +46 (0)8 657 61 00
            </Link>
          </Text>
          <Text>
            Postal address:
            <br />
            Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm, Sweden
          </Text>
        </Section>
      </Stack>
    </Container>
  );
};

export default PrivacyPolicyPage;
