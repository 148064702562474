import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FaCog, FaHome, FaShieldAlt, FaSignOutAlt } from 'react-icons/fa';
import { useAuth, useUser } from 'reactfire';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useClaims } from '@larpcalendar/firebase';

/* eslint-disable-next-line */
export interface UserMenuProps {}

export const UserMenu: FunctionComponent<UserMenuProps> = (_props) => {
  const { status, data: user } = useUser();
  const firebaseAuth = useAuth();
  const toast = useToast();
  const {
    claims: { isAdmin },
  } = useClaims();

  const onSignOutClick = async () => {
    try {
      await signOut(firebaseAuth);
      toast({
        title: 'Signed out',
        description: 'Come back soon!',
        status: 'info',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const itemColor = useColorModeValue('gray.800', 'gray.400');

  return (
    <Box data-testid="UserMenu">
      {status === 'loading' ? (
        <Button disabled={true} data-testid="UserMenu--loading">
          <Spinner />
        </Button>
      ) : user ? (
        <Menu data-testid="UserMenu--signedIn">
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {user.displayName || 'Logged in'}
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<FaHome />}
              color={itemColor}
              as={Link}
              to="/dashboard"
            >
              Dashboard
            </MenuItem>
            <MenuItem
              icon={<FaCog />}
              color={itemColor}
              as={Link}
              to="/settings"
            >
              Settings
            </MenuItem>
            {isAdmin && (
              <>
                <MenuDivider />
                <MenuItem
                  icon={<FaShieldAlt />}
                  color={itemColor}
                  as={Link}
                  to="/admin"
                >
                  Admin Panel
                </MenuItem>
              </>
            )}
            <MenuDivider />
            <MenuItem
              onClick={onSignOutClick}
              icon={<FaSignOutAlt />}
              color={itemColor}
            >
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Button as={Link} to="/sign-in" data-testid="UserMenu--signedOut">
          Sign in
        </Button>
      )}
    </Box>
  );
};

export default UserMenu;
