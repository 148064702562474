import { ObservableStatus, useFirestore, useFirestoreDocData } from 'reactfire';

import { LarpEvent } from '@larpcalendar/types';
import { doc } from '@firebase/firestore';
import { eventConverter } from '../event-converter/event-converter';

export const useEvent = (id: string): ObservableStatus<LarpEvent> => {
  const firestore = useFirestore();
  const ref = doc(firestore, 'events', id).withConverter(eventConverter);
  return useFirestoreDocData(ref);
};

export default useEvent;
