import { FunctionComponent, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ClaimsValidator } from 'reactfire';
import { Protected } from '../protected/protected';

const AdminPage = lazy(() => import('../admin-page/admin-page'));

const claimsValidator: ClaimsValidator = (userClaims) => ({
  hasRequiredClaims: !!userClaims.isAdmin,
  errors: {},
});

/* eslint-disable-next-line */
export interface AdminRouterProps {}

export const AdminRouter: FunctionComponent<AdminRouterProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Protected validateCustomClaims={claimsValidator}>
      <Switch>
        <Route exact path={path}>
          <AdminPage />
        </Route>
      </Switch>
    </Protected>
  );
};

export default AdminRouter;
