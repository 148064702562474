import { Text, TextProps } from '@chakra-ui/react';

import { FunctionComponent } from 'react';

export interface MultiLineTextProps extends TextProps {
  children: string;
}

export const MultiLineText: FunctionComponent<MultiLineTextProps> = ({
  children,
  ...rest
}) => {
  const paragraphs = children.split('\n\n');
  const paragraphMapper = (paragraph: string) => (
    <Text {...rest} key={paragraph}>
      {paragraph}
    </Text>
  );

  return children?.trim()?.length ? (
    <>{paragraphs.map(paragraphMapper)}</>
  ) : null;
};

export default MultiLineText;
