import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';

export interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  alt?: string;
  canonical?: string;
}

const SITE_NAME = 'Larp Calendar';

export const Meta: FunctionComponent<MetaProps> = ({
  title,
  description = 'Your one-stop shop for finding international larps!',
  image,
  alt,
  canonical,
  children,
}) => {
  const fullTitle = title ? `${title} | ${SITE_NAME}` : SITE_NAME;
  const url = canonical ? canonical : window.location.toString();
  const locale = 'en_US';
  const imageUrl = image
    ? image
    : `${window.location.origin}/assets/og-image.png`;
  const imageAlt =
    image && alt ? alt : image && !alt ? SITE_NAME : 'Larp Calendar logo';
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={SITE_NAME} />
      {children}
    </Helmet>
  );
};

export default Meta;
