import {
  AlertBox,
  AlertBoxProps,
  FlashMessage,
  PageLoading,
  RegisterForm,
  Section,
} from '@larpcalendar/web-ui';
import {
  Button,
  ButtonGroup,
  Container,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';

import { FirebaseError } from 'firebase/app';
import { FormikHelpers } from 'formik';
import { Meta } from '../meta/meta';
import { RegisterFormValues } from '@larpcalendar/types';
import { Link as RouterLink } from 'react-router-dom';
import { createUserWithEmailAndPassword } from '@firebase/auth';
import { useAuth } from 'reactfire';

/* eslint-disable-next-line */
export interface RegisterPageProps {}

export const RegisterPage: FunctionComponent<RegisterPageProps> = () => {
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(false);
  const [error, setError] = useState<Partial<AlertBoxProps> | null>(null);
  const firebaseAuth = useAuth();
  const toast = useToast();
  const onSubmit = async (
    values: RegisterFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<RegisterFormValues>
  ) => {
    try {
      setError(null);
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        values.email,
        values.password
      );
      const description = userCredential?.user?.displayName
        ? `Welcome ${userCredential.user.displayName}`
        : 'Welcome!';
      toast({
        title: 'Account registered',
        description,
        status: 'success',
      });
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/email-already-in-use'
      ) {
        console.error(error.code, error.message);
        setFieldError(
          'email',
          'There is a already an account registered with this email address'
        );
      } else {
        setError({
          title: "Couldn't register",
          description: 'Please try again!',
        });
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    // @TODO proper feature flag
    setIsEnabled(process.env.NODE_ENV !== 'production');
  }, []);

  return typeof isEnabled === 'undefined' ? (
    <PageLoading testid="RegisterPage" />
  ) : (
    <Container px={0} data-testid="RegisterPage">
      <Meta title="Register User Account" />
      {isEnabled ? (
        <Stack spacing={6}>
          <FlashMessage />
          <Section id="register">
            <Heading as="h1">Register User Account</Heading>
            <Text>
              You need to register an account to add events to the calendar.
              Accounts are <strong>personal</strong> and should reflect you as
              an individual.
            </Text>
            <Text>
              After registration you will be able to create an organizer profile
              to add events from. Several user accounts can be connected to an
              organizer profile. A user account can be connected to several
              organizer profiles.
            </Text>
            <Text fontSize="xl">
              Do not sign up with a shared account for an organization! Accounts
              are personal!
            </Text>
          </Section>
          <Section>
            <Stack alignItems="center" width="100%">
              <RegisterForm onSubmit={onSubmit} />
              {error && <AlertBox {...error} center={false} />}
              <ButtonGroup variant="ghost" justifyContent="center">
                <Button
                  as={RouterLink}
                  to="/sign-in"
                  fontSize="sm"
                  textDecoration="underline"
                >
                  Sign in
                </Button>
                <Button
                  as={RouterLink}
                  to="/sign-in/forgot-password"
                  fontSize="sm"
                  textDecoration="underline"
                  role="button"
                >
                  Forgot password?
                </Button>
              </ButtonGroup>
            </Stack>
          </Section>
        </Stack>
      ) : (
        <Section id="register">
          <Heading as="h1">Account Registration Closed</Heading>
          <Text>
            It's not possible to register new user accounts at the moment.
          </Text>
        </Section>
      )}
    </Container>
  );
};

export default RegisterPage;
