import { BreadcrumbData, Breadcrumbs } from '@larpcalendar/web-ui';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';

/* eslint-disable-next-line */
export interface PageErrorProps {
  title?: string;
  crumbs?: BreadcrumbData[];
}

export const PageError: FunctionComponent<PageErrorProps> = ({
  title = 'Error',
  crumbs = [{ to: '', label: title }],
  children = <Text>Something went amiss, sorry!</Text>,
}) => {
  return (
    <Stack>
      <Meta title={title} />
      <Breadcrumbs crumbs={crumbs} />
      <Heading as="h1">{title}</Heading>
      {children}
    </Stack>
  );
};

export default PageError;
