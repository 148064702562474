import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { FunctionComponent } from 'react';

export const DividerWithText: FunctionComponent<FlexProps> = ({
  children,
  ...rest
}) => (
  <Flex align="center" color="gray.300" width="100%" {...rest}>
    <Box flex="1">
      <Divider borderColor="currentcolor" />
    </Box>
    <Text
      as="span"
      px="3"
      color={useColorModeValue('gray.600', 'gray.400')}
      fontWeight="medium"
    >
      {children}
    </Text>
    <Box flex="1">
      <Divider borderColor="currentcolor" />
    </Box>
  </Flex>
);

export default DividerWithText;
