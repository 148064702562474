import { FunctionComponent } from 'react';
import { Stack } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface SectionProps {
  id?: string;
  variant?: 'bleed' | undefined;
}

export const Section: FunctionComponent<SectionProps> = ({
  children,
  variant,
  id,
}) => {
  return (
    <Stack
      as="section"
      spacing={3}
      id={id}
      width="100%"
      alignItems="flex-start"
      maxWidth={variant === 'bleed' ? undefined : 'calc(100vw - 2rem)'}
      mx="auto"
    >
      {children}
    </Stack>
  );
};

export default Section;
