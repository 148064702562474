import { ClaimsValidator, useSigninCheck } from 'reactfire';
import { FunctionComponent, ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { LarpCalendarLocationState } from '@larpcalendar/types';
import { LocationDescriptor } from 'history';
import { PageLoading } from '@larpcalendar/web-ui';

export interface ProtectedProps {
  children: ReactElement;
  validateCustomClaims?: ClaimsValidator;
  redirect?: string;
}

export const Protected: FunctionComponent<ProtectedProps> = ({
  children,
  validateCustomClaims,
  redirect = '/',
}) => {
  const { status, data } = useSigninCheck(
    validateCustomClaims && {
      validateCustomClaims,
    }
  );
  const { pathname, state } = useLocation<LarpCalendarLocationState>();
  const to: LocationDescriptor<LarpCalendarLocationState> = data?.signedIn
    ? { pathname: state?.referrer || redirect }
    : {
        pathname: '/sign-in',
        state: { referrer: pathname },
      };

  return status === 'loading' ? (
    <PageLoading />
  ) : status === 'success' && data?.hasRequiredClaims ? (
    children
  ) : (
    <Redirect to={to} />
  );
};

export default Protected;
