import { useEffect, useState } from 'react';

import { CustomClaims } from '@larpcalendar/types';
import { useUser } from 'reactfire';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseClaims {
  status: 'loading' | 'error' | 'success';
  claims: CustomClaims;
}

export const useClaims = (): UseClaims => {
  const { status, data } = useUser();
  const [claims, setClaims] = useState<CustomClaims>({});

  const getClaims = async () => {
    const result = await data?.getIdTokenResult();
    if (result?.claims) {
      setClaims({ isAdmin: !!result?.claims?.isAdmin || false });
    }
  };

  useEffect(() => {
    if (data) {
      getClaims();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { status, claims };
};

export default useClaims;
