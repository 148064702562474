import { Link, LinkProps } from 'react-router-dom';

import { Button } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export type HeaderButtonProps = Pick<LinkProps, 'to'>;

export const HeaderButton: FunctionComponent<HeaderButtonProps> = ({
  to,
  children,
}) => {
  return (
    <Button as={Link} to={to} textDecoration="none">
      {children}
    </Button>
  );
};

export default HeaderButton;
