import { Box, Stack } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Organizer } from '@larpcalendar/types';
import { OrganizerCard } from '../organizer-card/organizer-card';

export interface OrganizerCardListProps {
  organizers?: Organizer[];
  loading?: boolean;
}

export const OrganizerCardList: FunctionComponent<OrganizerCardListProps> = ({
  organizers = [],
  loading,
}) => {
  const organizerMapper = (organizer: Organizer) => {
    return (
      <OrganizerCard
        organizer={organizer}
        key={`OrganizerCard-${organizer.id}`}
      />
    );
  };
  return (
    <Stack>
      {loading && <Box>Loading...</Box>}
      {!organizers.length && <Box>No organizers!</Box>}
      {organizers.map(organizerMapper)}
    </Stack>
  );
};

export default OrganizerCardList;
