import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';

import { FaRegEdit } from 'react-icons/fa';
import { MotionBox } from '../motion-box/motion-box';
import { Variants } from 'framer-motion';
import uniqid from 'uniqid';

export interface SettingsItemProps {
  label: string;
  value?: string;
  isLoading?: boolean;
  status: string | undefined;
}
export const SettingsItem: FunctionComponent<SettingsItemProps> = ({
  label,
  value = '',
  isLoading,
  status,
  children,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const labelId = uniqid('SettingsItem-');
  const contentId = uniqid('SettingsItem-');
  const variants: Variants = {
    closed: { height: 0, transition: { duration: 0.3 } },
    open: { height: 'auto', transition: { duration: 0.3 } },
  };

  useEffect(() => {
    if (status === 'success' || status === 'aborted') {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Stack p={0} w="100%">
      <HStack w="100%" justifyContent="space-between">
        <Stack>
          <Text fontWeight="bold" id={labelId}>
            {label}
          </Text>
          <Text>{value}</Text>
        </Stack>
        <ButtonGroup>
          <Button
            onClick={onToggle}
            rightIcon={!isOpen ? <FaRegEdit /> : undefined}
            isLoading={isLoading}
            aria-controls={contentId}
            aria-expanded={isOpen}
          >
            {isOpen ? 'Cancel' : 'Edit'}
          </Button>
        </ButtonGroup>
      </HStack>
      <MotionBox
        animate={isOpen ? 'open' : 'closed'}
        variants={variants}
        initial={false}
        overflowY="hidden"
        aria-labelledby={labelId}
      >
        <Box paddingTop={4}>{children}</Box>
      </MotionBox>
    </Stack>
  );
};

export default SettingsItem;
