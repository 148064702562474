import * as Yup from 'yup';

import { Button, Stack, useToast } from '@chakra-ui/react';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent, useEffect } from 'react';

import { FaArrowRight } from 'react-icons/fa';
import { FirebaseError } from 'firebase/app';
import { Form } from '../form/form';
import { FormField } from '../form-field/form-field';
import { SettingsEmailValues } from '@larpcalendar/types';
import { SettingsItem } from '../settings-item/settings-item';
import { SettingsVerifyEmail } from '../settings-verify-email/settings-verify-email';
import { Submit } from '../submit/submit';
import { updateEmail } from '@firebase/auth';
import { useUser } from 'reactfire';

/* eslint-disable-next-line */
export interface SettingsEmailProps {}

export const SettingsEmail: FunctionComponent<SettingsEmailProps> = () => {
  const { status: userStatus, data: user } = useUser();
  const toast = useToast();

  const onCancel = () => {
    resetForm();
  };

  useEffect(() => {
    // Avoid race condition
    if (user) {
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (
    values: SettingsEmailValues,
    {
      setSubmitting,
      setStatus,
      setFieldError,
    }: FormikHelpers<SettingsEmailValues>
  ) => {
    try {
      setStatus(undefined);
      if (!user) {
        throw new Error('User not available');
      }
      const email = values.email.trim();
      if (!email) {
        throw new Error('Email is empty');
      }
      if (!user.email) {
        throw new Error('User email not found');
      }
      const credential = EmailAuthProvider.credential(
        user.email,
        values.currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, email);
      await user.reload();
      resetForm({ values: { ...initialValues, email } });
      setSubmitting(false);
      setStatus('success');
      toast({
        title: 'Email updated',
        description: `Your new email is ${email}`,
        status: 'success',
      });
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/wrong-password'
      ) {
        setFieldError('currentPassword', 'Incorrect password!');
      } else {
        console.error(error);
      }
      setStatus('error');
      setSubmitting(false);
      toast({
        title: 'Something went wrong',
        description: "Couldn't update your email",
        status: 'error',
      });
    }
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .trim()
      .required('You need to enter your current password'),
    email: Yup.string()
      .trim()
      .email('You need to enter a valid email address')
      .notOneOf(
        [user?.email],
        'Your new email address can\t be the same as the old one'
      )
      .required('You need to enter an email address'),
  });

  const initialValues = { currentPassword: '', email: user?.email || '' };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    resetForm,
    status,
    isValid,
    dirty,
  } = useFormik<SettingsEmailValues>({
    onSubmit,
    validationSchema,
    initialValues,
  });

  return (
    <Stack>
      <SettingsItem
        label="Email Address"
        value={user?.email || ''}
        status={status}
      >
        <Form onSubmit={handleSubmit}>
          <FormField
            label="Current Password"
            name="currentPassword"
            value={values.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={isValid}
            isSubmitting={isSubmitting}
            error={errors.currentPassword}
            type="password"
            isRequired
          />
          <FormField
            label="New Email Address"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={isValid}
            isSubmitting={isSubmitting}
            error={errors.email}
            isRequired
          />
          <Submit isLoading={isSubmitting} rightIcon={<FaArrowRight />}>
            Save
          </Submit>
        </Form>
      </SettingsItem>
      <SettingsVerifyEmail />
    </Stack>
  );
};

export default SettingsEmail;
