import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const ScrollToTop = () => {
  const history = useHistory<unknown>();

  useEffect(() => {
    const unlisten = history.listen((_location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default ScrollToTop;
