import {
  Box,
  Container,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { EventTicket, LarpEvent } from '@larpcalendar/types';

import Card from '../card/card';
import CardContent from '../card-content/card-content';
import CardHeader from '../card-header/card-header';
import CardProperty from '../card-property/card-property';
import { DetailsAction } from '../details-action/details-action';
import { EventDate } from '../event-date/event-date';
import { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface EventCardProps {
  event: LarpEvent;
}

export const EventCard: FunctionComponent<EventCardProps> = ({
  event: {
    id,
    name,
    startDate,
    endDate,
    location,
    eventType,
    capacity,
    tickets = [],
    snippet,
  },
}) => {
  if (!name) {
    return null;
  }

  const ticketsMapper = (ticket: EventTicket, index: number) => {
    const locale = 'en-US';
    if (ticket?.price === 'free') {
      return <Text key={`Ticket-${id}-${index}`}>Free</Text>;
    } else if (
      ticket?.price?.amount &&
      ticket?.price?.currency &&
      typeof ticket.price.amount === 'number'
    ) {
      return (
        <Text>
          {new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: ticket.price.currency,
          }).format(ticket.price.amount)}
        </Text>
      );
    } else {
      return null;
    }
  };

  return (
    <LinkBox as="article" data-testid="EventCard">
      <Card mx="auto">
        <CardHeader
          title={name}
          type={eventType}
          action={<DetailsAction to={`/events/${id}`} />}
        />
        <CardContent>
          {!!snippet?.length && (
            <Box p={4} borderBottomWidth="1px">
              <Container p={0}>
                <Text>{snippet}</Text>
              </Container>
            </Box>
          )}
          <CardProperty label="Date">
            <EventDate startDate={startDate} endDate={endDate} />
          </CardProperty>
          {location && (
            <CardProperty label="Location">
              <Text>
                {location.name}
                {location.city && `${location.name && ', '}${location.city}`}
                {location.country &&
                  `${(location.name || location.city) && ', '}${
                    location.country
                  }`}
              </Text>
            </CardProperty>
          )}
        </CardContent>
      </Card>
    </LinkBox>
  );
};

export default EventCard;
