import * as Yup from 'yup';

import { LarpCalendarFormProps, SignInFormValues } from '@larpcalendar/types';

import { FaSignInAlt } from 'react-icons/fa';
import { Form } from '../form/form';
import { FormField } from '../form-field/form-field';
import { FunctionComponent } from 'react';
import { Submit } from '../submit/submit';
import { useFormik } from 'formik';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('You need to enter a valid email address')
    .required('You need to enter an email address'),
  password: Yup.string().required('You need to enter a password'),
});

export interface SignInFormProps
  extends LarpCalendarFormProps<SignInFormValues> {
  isInvalid?: boolean;
}

export const SignInForm: FunctionComponent<SignInFormProps> = ({
  onSubmit,
}) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isValid,
    isSubmitting,
    errors,
    values,
  } = useFormik<SignInFormValues>({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FormField
        label="Email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.email}
        type="email"
        inputProps={{ autoComplete: 'email', inputMode: 'email' }}
        isRequired
      />
      <FormField
        label="Password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.password}
        type="password"
        isRequired
      />
      <Submit isLoading={isSubmitting} rightIcon={<FaSignInAlt />}>
        Sign in
      </Submit>
    </Form>
  );
};

export default SignInForm;
