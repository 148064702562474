import {
  Breadcrumbs,
  OrganizerCard,
  OrganizerCardList,
  PageLoading,
} from '@larpcalendar/web-ui';
import { Container, Heading, Stack } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { Organizer } from '@larpcalendar/types';
import { PageError } from '../page-error/page-error';
import { useLocation } from 'react-router-dom';
import { useOrganizers } from '@larpcalendar/firebase';

/* eslint-disable-next-line */
export interface OrganizersPageProps {}

export const OrganizersPage: FunctionComponent<OrganizersPageProps> = () => {
  const { data, status } = useOrganizers();
  const { pathname } = useLocation();

  return status === 'loading' ? (
    <PageLoading />
  ) : data ? (
    <Container px={0}>
      <Stack>
        <Meta title="Organizers" />
        <Breadcrumbs crumbs={[{ label: 'Organizers', to: pathname }]} />
        <Heading as="h1">Organizers</Heading>
        <OrganizerCardList organizers={data} />
      </Stack>
    </Container>
  ) : (
    <PageError />
  );
};

export default OrganizersPage;
