import { Redirect, useLocation } from 'react-router-dom';
import { FunctionComponent } from 'react';

import RecoverEmailPage from '../recover-email-page/recover-email-page';
import ResetPasswordPage from '../reset-password-page/reset-password-page';
import VerifyEmailPage from '../verify-email-page/verify-email-page';

/* eslint-disable-next-line */
export interface AuthActionProps {}

export const AuthAction: FunctionComponent<AuthActionProps> = () => {
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const mode = urlSearchParams.get('mode');
  const oobCode = urlSearchParams.get('oobCode');
  const continueUrl = urlSearchParams.get('continueUrl') || undefined;
  const lang = urlSearchParams.get('lang') || undefined;

  console.log('AuthAction');

  if (mode && oobCode) {
    console.log('mode & oobCode set');
    switch (mode) {
      case 'resetPassword':
        console.log('resetPassword');
        return (
          <ResetPasswordPage
            oobCode={oobCode}
            continueUrl={continueUrl}
            lang={lang}
          />
        );
      case 'recoverEmail':
        // Display email recovery handler and UI.
        // handleRecoverEmail(firebaseAuth, oobCode, lang);
        console.log('recoverEmail');
        return (
          <RecoverEmailPage
            oobCode={oobCode}
            continueUrl={continueUrl}
            lang={lang}
          />
        );
      case 'verifyEmail':
        // Display email verification handler and UI.
        // handleVerifyEmail(firebaseAuth, oobCode, continueUrl, lang);
        console.log('verifyEmail');
        return (
          <VerifyEmailPage
            oobCode={oobCode}
            continueUrl={continueUrl}
            lang={lang}
          />
        );
      default:
        console.log('invalid action, go home');
        return <Redirect to="/" />;
    }
  } else {
    console.log('no mode or oobCode, go home');
    return <Redirect to="/" />;
  }
};

export default AuthAction;
