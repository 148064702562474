import { Container, Heading, Link, Stack, Text } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { Section } from '@larpcalendar/web-ui';

/* eslint-disable-next-line */
export interface AboutPageProps {}

export const AboutPage: FunctionComponent<AboutPageProps> = (_props) => {
  return (
    <Container px={0}>
      <Meta title="About" />
      <Stack spacing={8}>
        <Section>
          <Heading as="h1">About Larp Calendar</Heading>
          <Text>
            <em>Larp Calendar</em> is a website for keeping track of larp events
            accepting international participants.
          </Text>
          <Text>
            The page is made by Johannes Axner as part of the larger Nordic Larp
            family of websites:{' '}
            <Link
              href="https://nordiclarp.org/"
              isExternal
              textDecoration="underline"
            >
              https://nordiclarp.org/
            </Link>
          </Text>
        </Section>
        <Section>
          <Heading fontSize="2xl">Contribute</Heading>
          <Text>
            If you want to contribute to the code of the website you can check
            it out on Github:{' '}
            <Link
              href="https://github.com/nordiclarp/larpcalendar"
              isExternal
              textDecoration="underline"
            >
              https://github.com/nordiclarp/larpcalendar
            </Link>
          </Text>
        </Section>
        <Section>
          <Heading fontSize="2xl">Donate</Heading>
          <Text>
            Larp Calendar is made is made on free time and all costs paid for
            out of pocket. If you want to help us pay the bills, please consider
            donating monthly to the Nordic Larp Patreon:{' '}
            <Link
              href="https://www.patreon.com/nordiclarp"
              isExternal
              textDecoration="underline"
            >
              https://www.patreon.com/nordiclarp
            </Link>
          </Text>
          <Text>
            If you want to make a one-time donation you can do so with PayPal:{' '}
            <Link
              href="https://paypal.me/johannesaxner"
              isExternal
              textDecoration="underline"
            >
              https://paypal.me/johannesaxner
            </Link>
          </Text>
        </Section>
      </Stack>
    </Container>
  );
};

export default AboutPage;
