import { FunctionComponent, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const OrganizersPage = lazy(() => import('../organizers-page/organizers-page'));
const OrganizerDetailPage = lazy(
  () => import('../organizer-detail-page/organizer-detail-page')
);

/* eslint-disable-next-line */
export interface OrganizersRouterProps {}

export const OrganizersRouter: FunctionComponent<OrganizersRouterProps> =
  () => {
    const { path } = useRouteMatch();

    return (
      <Switch>
        <Route exact path={path}>
          <OrganizersPage />
        </Route>
        <Route path={`${path}/:id`}>
          <OrganizerDetailPage />
        </Route>
      </Switch>
    );
  };

export default OrganizersRouter;
