import { Breadcrumbs, EventCardList, PageLoading } from '@larpcalendar/web-ui';
import { Container, Heading, Stack } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { PageError } from '../page-error/page-error';
import { useEvents } from '@larpcalendar/firebase';
import { useLocation } from 'react-router-dom';

/* eslint-disable-next-line */
export interface EventsPageProps {}

export const EventsPage: FunctionComponent<EventsPageProps> = () => {
  const { data, status } = useEvents();

  const { pathname } = useLocation();

  return status === 'loading' ? (
    <PageLoading />
  ) : data ? (
    <Container px={0}>
      <Stack>
        <Meta title="Events" />
        <Breadcrumbs crumbs={[{ label: 'Events', to: pathname }]} />
        <Heading as="h1">Events</Heading>
        <EventCardList events={data} />
      </Stack>
    </Container>
  ) : (
    <PageError />
  );
};

export default EventsPage;
