import {
  AlertBox,
  AlertBoxProps,
  RegisterOrganizerForm,
  Section,
} from '@larpcalendar/web-ui';
import { Container, Heading, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { addDoc, collection } from '@firebase/firestore';

import { FirebaseError } from '@firebase/util';
import { FormikHelpers } from 'formik';
import { Meta } from '../meta/meta';
import { RegisterOrganizerFormValues } from '@larpcalendar/types';
import { useFirestore } from 'reactfire';

/* eslint-disable-next-line */
export interface RegisterOrganizerPageProps {}

export const RegisterOrganizerPage: FunctionComponent<RegisterOrganizerPageProps> =
  () => {
    const firestore = useFirestore();
    const [error, setError] = useState<Partial<AlertBoxProps> | null>(null);

    const onSubmit = async (
      values: RegisterOrganizerFormValues,
      _formikHelpers: FormikHelpers<RegisterOrganizerFormValues>
    ) => {
      // console.log(values);
      const { name, description, url, email } = values;
      try {
        setError(null);
        const docRef = await addDoc(
          collection(firestore, 'pendingOrganizers'),
          {
            name,
            description,
            url,
            email,
          }
        );
        console.log(docRef);
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'permission-denied':
              setError({
                title: 'Permission denied',
                description:
                  'You must be logged in to register an organizer profile',
              });
              break;
            default:
              setError({
                title: 'Something went wrong',
                description: "Couldn't register organizer profile!",
              });
          }
        } else {
          setError({
            title: 'Something went wrong',
            description: "Couldn't register organizer profile!",
          });
        }
      }
    };
    return (
      <Container px={0}>
        <Meta title="Contact" />
        <Stack>
          <Section>
            <Heading as="h1">Register Organizer</Heading>
            <Text>
              To be able to publish events on Larp Calender you need to register
              as an organizer. By doing this you assure that you represent the
              organizers of the events you publish.
            </Text>
          </Section>
          <Section>
            <RegisterOrganizerForm onSubmit={onSubmit} />
            {error && <AlertBox {...error} center={false} />}
          </Section>
        </Stack>
      </Container>
    );
  };

export default RegisterOrganizerPage;
