import {
  ObservableStatus,
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import { collection, query } from 'firebase/firestore';

import { Organizer } from '@larpcalendar/types';
import { organizerConverter } from '../organizer-converter/organizer-converter';

export const useOrganizers = (): ObservableStatus<Organizer[]> => {
  const firestore = useFirestore();
  const collectionReference = collection(firestore, 'organizers').withConverter(
    organizerConverter
  );
  const collectionQuery = query(collectionReference);
  return useFirestoreCollectionData(collectionQuery, {
    idField: 'id',
  });
};

export default useOrganizers;
