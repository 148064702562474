import { FormEvent, FunctionComponent } from 'react';
import { Stack, chakra, useColorModeValue } from '@chakra-ui/react';

export interface FormProps {
  onSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

export const Form: FunctionComponent<FormProps> = ({ children, onSubmit }) => {
  return (
    <chakra.form
      onSubmit={onSubmit}
      width="100%"
      padding={4}
      bg={useColorModeValue('white', 'gray.700')}
      shadow="base"
      rounded="md"
    >
      <Stack spacing={6}>{children}</Stack>
    </chakra.form>
  );
};

export default Form;
