import { FunctionComponent } from 'react';

export interface RecoverEmailPageProps {
  oobCode: string;
  continueUrl?: string;
  lang?: string;
}

export const RecoverEmailPage: FunctionComponent<RecoverEmailPageProps> = ({
  oobCode,
  continueUrl = '/sign-in',
  lang = 'en',
}) => {
  return (
    <div>
      <h1>Welcome to RecoverEmailPage!</h1>
    </div>
  );
};

export default RecoverEmailPage;
