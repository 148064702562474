import {
  Breadcrumbs,
  LocationBlock,
  MultiLineText,
  PageLoading,
} from '@larpcalendar/web-ui';
import { Heading, Link, Stack, Text } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';

import { FunctionComponent } from 'react';
import { Meta } from '../meta/meta';
import { useOrganizer } from '@larpcalendar/firebase';

/* eslint-disable-next-line */
export interface OrganizerDetailPageProps {}

export interface OrganizerDetailPageParams {
  id: string;
}
export const OrganizerDetailPage: FunctionComponent<OrganizerDetailPageProps> =
  () => {
    const { id = '' } = useParams<OrganizerDetailPageParams>();
    const { status, data } = useOrganizer(id);
    const { pathname } = useLocation();

    return status === 'loading' ? (
      <PageLoading testid="OrganizerDetailPage" />
    ) : data ? (
      <Stack data-testid="OrganizerDetailPage">
        <Meta title="Organizers" />
        <Breadcrumbs
          crumbs={[
            { label: 'Organizers', to: '/organizers' },
            { to: pathname, label: data.name || 'Organizer Detail Page' },
          ]}
        />
        <Heading as="h1">{data.name}</Heading>
        {data.location && <LocationBlock location={data.location} />}
        {data.description && <MultiLineText>{data.description}</MultiLineText>}\
        {data.email && (
          <Text>
            <Link href={`mailto:${data.email}`} textDecoration="underline">
              {data.email}
            </Link>
          </Text>
        )}
        {data.url && (
          <Text>
            <Link href={data.url} textDecoration="underline" isExternal>
              {data.url}
            </Link>
          </Text>
        )}
      </Stack>
    ) : (
      <Stack data-testid="OrganizerDetailPage">
        <Meta title="Organizer Not Found" />
        <Breadcrumbs
          crumbs={[
            { label: 'Organizers', to: '/organizers' },
            { to: pathname, label: 'Organizer Not Found' },
          ]}
        />
        <Heading as="h1">Organizer Not Found</Heading>
        <Text>We couldn't find the organizer you are looking for, sorry!</Text>
      </Stack>
    );
  };

export default OrganizerDetailPage;
