import {
  Button,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  VisuallyHidden,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FunctionComponent, useRef } from 'react';
import {
  ReactChild,
  ReactElement,
  useEffect,
} from 'react-router/node_modules/@types/react';

export interface FormFieldProps {
  label?: ReactChild;
  labelComponent?: ReactChild;
  isValid: boolean;
  isSubmitting: boolean;
  error: string | undefined;
  help?: ReactChild;
  name: InputProps['name'];
  value: InputProps['value'] | boolean;
  type?: InputProps['type'];
  inputProps?: InputProps;
  checkboxProps?: CheckboxProps;
  onBlur: InputProps['onBlur'];
  onChange: InputProps['onChange'];
  isRequired?: boolean;
}

export const FormField: FunctionComponent<FormFieldProps> = ({
  isValid,
  isSubmitting,
  error,
  value,
  onChange,
  onBlur,
  label,
  help,
  name,
  inputProps = {},
  checkboxProps = {},
  isRequired = false,
  type,
  children,
  labelComponent = FormLabel,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, { toggle }] = useBoolean();
  const bg = useColorModeValue('white', 'gray.800');

  const onClickReveal = () => {
    toggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <FormControl
      isDisabled={isSubmitting}
      isInvalid={!isValid && !!error}
      isRequired={isRequired}
    >
      {/* {labelComponent && <>{labelComponent}</>} */}
      {label && typeof label === 'string' ? (
        <FormLabel>{label}</FormLabel>
      ) : (
        label && label
      )}

      {type === 'checkbox' ? (
        <Checkbox
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          checked={value as boolean}
          {...checkboxProps}
        >
          {children}
        </Checkbox>
      ) : type === 'password' ? (
        <InputGroup>
          <Input
            name={name}
            value={value as InputProps['value']}
            onChange={onChange}
            onBlur={onBlur}
            type={showPassword ? 'text' : 'password'}
            bg={bg}
            ref={inputRef}
            {...inputProps}
          />
          <InputRightAddon px={0}>
            <IconButton
              onClick={onClickReveal}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
              title={showPassword ? 'Hide password' : 'Show password'}
              icon={showPassword ? <FaEye /> : <FaEyeSlash />}
              borderStartRadius={0}
              variant="ghost"
            />
          </InputRightAddon>
        </InputGroup>
      ) : (
        <Input
          name={name}
          value={value as InputProps['value']}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          bg={bg}
          {...inputProps}
        />
      )}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  );
};

export default FormField;
