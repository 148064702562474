import { FunctionComponent, useState } from 'react';

import { AlertBox } from '../alert-box/alert-box';
import { LarpCalendarLocationState } from '@larpcalendar/types';
import { useLocation } from 'react-router-dom';

/* eslint-disable-next-line */
export interface FlashMessageProps {}

export const FlashMessage: FunctionComponent<FlashMessageProps> = () => {
  const { state: { flash } = {} } = useLocation<LarpCalendarLocationState>();
  const [visible, setVisible] = useState<boolean>(!!flash);
  const onClose = () => {
    setVisible(false);
  };
  return !!flash && visible ? (
    <AlertBox {...flash} center={true} onClose={onClose} />
  ) : null;
};

export default FlashMessage;
