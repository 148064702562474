import * as Yup from 'yup';

import { FormikHelpers, useFormik } from 'formik';

import { FaArrowRight } from 'react-icons/fa';
import { Form } from '../form/form';
import { FormField } from '../form-field/form-field';
import { FunctionComponent } from 'react';
import { SettingsDisplayNameValues } from '@larpcalendar/types';
import { SettingsItem } from '../settings-item/settings-item';
import { Submit } from '../submit/submit';
import { updateProfile } from '@firebase/auth';
import { useToast } from '@chakra-ui/react';
import { useUser } from 'reactfire';

/* eslint-disable-next-line */
export interface SettingsDisplayNameProps {}

export const SettingsDisplayName: FunctionComponent<SettingsDisplayNameProps> =
  () => {
    const { status: userStatus, data: user } = useUser();
    const toast = useToast();

    const onSubmit = async (
      values: SettingsDisplayNameValues,
      {
        setSubmitting,
        setStatus,
        resetForm,
      }: FormikHelpers<SettingsDisplayNameValues>
    ) => {
      try {
        setStatus(undefined);
        if (!user) {
          throw new Error('User not available');
        }
        const displayName = values.displayName.trim();
        if (!displayName) {
          throw new Error('Display Name is empty');
        }
        await updateProfile(user, { displayName });
        await user.reload();
        resetForm({ values: { displayName } });
        setSubmitting(false);
        setStatus('success');
        toast({
          title: 'Display name updated',
          description: `Your new display name is ${displayName}`,
          status: 'success',
        });
      } catch (error) {
        console.error(error);
        setStatus('error');
        setSubmitting(false);
        toast({
          title: 'Something went wrong',
          description: "Couldn't update your display name",
          status: 'error',
        });
      }
    };

    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .trim()
        .not(
          [user?.displayName],
          "New display name can't be the same as the last one"
        ),
    });

    const initialValues: SettingsDisplayNameValues = {
      displayName: user?.displayName || '',
    };

    const {
      handleBlur,
      handleChange,
      handleSubmit,
      values,
      errors,
      isSubmitting,
      status,
      isValid,
      dirty,
    } = useFormik<SettingsDisplayNameValues>({
      onSubmit,
      validationSchema,
      initialValues,
    });

    return (
      <SettingsItem
        label="Display Name"
        value={user?.displayName || ''}
        status={status}
      >
        <Form onSubmit={handleSubmit}>
          <FormField
            label="New Display Name"
            name="displayName"
            value={values.displayName}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={isValid}
            isSubmitting={isSubmitting}
            error={errors.displayName}
          />
          <Submit isLoading={isSubmitting} rightIcon={<FaArrowRight />}>
            Save
          </Submit>
        </Form>
      </SettingsItem>
    );
  };

export default SettingsDisplayName;
