import { Box } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

/* eslint-disable-next-line */
export interface BodyProps {}

export const Body: FunctionComponent<BodyProps> = ({ children }) => {
  return (
    <Box
      as="main"
      role="main"
      mx="auto"
      maxW="7xl"
      pt={{ base: '4', md: '8' }}
      pb={16}
      flex="1"
      d="flex"
    >
      {children}
    </Box>
  );
};

export default Body;
