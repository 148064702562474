import * as Yup from 'yup';

import { LarpCalendarFormProps, RegisterFormValues } from '@larpcalendar/types';

import { FaPen } from 'react-icons/fa';
import { Form } from '../form/form';
import { FormField } from '../form-field/form-field';
import { FunctionComponent } from 'react';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Submit } from '../submit/submit';
import { useFormik } from 'formik';

export const RegisterForm: FunctionComponent<
  LarpCalendarFormProps<RegisterFormValues>
> = ({ onSubmit }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('You need to enter a valid email address')
      .required('You need to enter an email address'),
    password: Yup.string()
      .min(6, 'Your password needs to be at least six characters long')
      .required('You need to enter a password'),
    confirmPassword: Yup.string()
      .when('password', {
        is: (value: string) => !!value?.length,
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Both passwords need to be the same'
        ),
      })
      .required('You need to confirm your password'),
    acceptPrivacy: Yup.boolean().isTrue(
      'You must give consent to having your personal information stored'
    ),
    acceptTerms: Yup.boolean().isTrue('You must accept our terms of service'),
  });
  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    acceptPrivacy: false,
    acceptTerms: false,
  };
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isValid,
    isSubmitting,
    errors,
    values,
  } = useFormik<RegisterFormValues>({
    onSubmit,
    validationSchema,
    initialValues,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FormField
        label="Email Address"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.email}
        isRequired
      />
      <FormField
        label="Password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.password}
        type="password"
        isRequired
      />
      <FormField
        label="Confirm Password"
        name="confirmPassword"
        value={values.confirmPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.confirmPassword}
        type="password"
        isRequired
      />
      <FormField
        label="Accept Privacy Policy"
        name="acceptPrivacy"
        value={values.acceptPrivacy}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.acceptPrivacy}
        type="checkbox"
        isRequired
        help={
          <>
            We use your email address to sign you in and contact you sparringly.
            It's never visible publically on the website and will never be
            shared with third parties. You can read more in our{' '}
            <Link
              as={RouterLink}
              to="/privacy-policy"
              textDecoration="underline"
              isExternal
            >
              privacy policy
            </Link>{' '}
            (opens in a new window/tab).
          </>
        }
      >
        I consent to my personal information being stored
      </FormField>
      <FormField
        label="Accept Terms of Service"
        name="acceptTerms"
        value={values.acceptTerms}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={isValid}
        isSubmitting={isSubmitting}
        error={errors.acceptTerms}
        type="checkbox"
        isRequired
        help={
          <>
            Take a minute to read through our{' '}
            <Link
              as={RouterLink}
              to="/terms-of-service"
              textDecoration="underline"
              isExternal
            >
              terms of service
            </Link>{' '}
            (opens in a new window/tab).
          </>
        }
      >
        I accept the terms of service
      </FormField>

      <Submit isLoading={isSubmitting} rightIcon={<FaPen />}>
        Register
      </Submit>
    </Form>
  );
};

export default RegisterForm;
