import * as Yup from 'yup';

import {
  AlertBox,
  AlertBoxProps,
  Form,
  FormField,
  PageLoading,
  Section,
  Submit,
} from '@larpcalendar/web-ui';
import { Container, Heading, Stack } from '@chakra-ui/react';
import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  LarpCalendarLocationState,
  ResetPasswordFormValues,
} from '@larpcalendar/types';
import { Redirect, useHistory } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from '@firebase/auth';

import { FaArrowRight } from 'react-icons/fa';
import { Meta } from '../meta/meta';
import { useAuth } from 'reactfire';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Your password needs to be at least six characters long')
    .required('You need to enter a password'),
  confirmPassword: Yup.string().when('password', {
    is: (value: string) => !!value?.length,
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both passwords need to be the same'
    ),
  }),
});

export interface ResetPasswordPageProps {
  oobCode: string;
  continueUrl?: string;
  lang?: string;
}

export const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = ({
  oobCode,
  continueUrl = '/sign-in',
  lang = 'en',
}) => {
  const firebaseAuth = useAuth();
  const [error, setError] = useState<Partial<AlertBoxProps> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const history = useHistory<LarpCalendarLocationState>();

  const verifyCode = async () => {
    try {
      const result = await verifyPasswordResetCode(firebaseAuth, oobCode);
      if (result) {
        setEmail(result);
      }
      setLoading(false);
    } catch (error) {
      // invalid code
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (
    values: ResetPasswordFormValues,
    { setSubmitting }: FormikHelpers<ResetPasswordFormValues>
  ) => {
    try {
      setError(null);
      await confirmPasswordReset(firebaseAuth, oobCode, values.newPassword);
      history.replace({
        pathname: continueUrl,
        state: {
          flash: {
            status: 'success',
            title: 'Password Updated!',
            description: 'You can now sign in with your new password.',
          },
        },
      });
    } catch (error) {
      console.error(error);
      setError({
        title: "Couldn't Reset Password!",
        description: 'Please try again',
      });
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    values,
    isValid,
  } = useFormik<ResetPasswordFormValues>({
    initialValues: { newPassword: '', confirmNewPassword: '' },
    validationSchema,
    onSubmit,
  });

  return loading ? (
    <PageLoading />
  ) : email ? (
    <Container px={0}>
      <Meta title="Reset Password" />
      <Stack spacing={6} data-testid="AuthPage">
        <Section id="resetPassword">
          <Heading as="h1">Reset Password</Heading>
          <Stack alignItems="center" width="100%">
            <Form onSubmit={handleSubmit}>
              <FormField
                label="New Password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={isValid}
                isSubmitting={isSubmitting}
                error={errors.newPassword}
                type="password"
                isRequired
              />
              <FormField
                label="Confirm New Password"
                name="confirmNewPassword"
                value={values.confirmNewPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={isValid}
                isSubmitting={isSubmitting}
                error={errors.confirmNewPassword}
                type="password"
                isRequired
              />
              <Submit isLoading={isSubmitting} rightIcon={<FaArrowRight />}>
                Set New Password
              </Submit>
            </Form>
            {error && <AlertBox {...error} center={false} />}
          </Stack>
        </Section>
      </Stack>
    </Container>
  ) : (
    <Redirect
      to={{
        pathname: '/sign-in/forgot-password',
        state: {
          flash: {
            status: 'warning',
            title: 'Reset Password Failed',
            description: 'Something went wrong, please try again!',
          },
        },
      }}
    />
  );
};

export default ResetPasswordPage;
