import {
  Box,
  ButtonGroup,
  Heading,
  Link,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

import ColorModeToggle from '../color-mode-toggle/color-mode-toggle';
import { FavIcon } from '@larpcalendar/web-icons';
import { FunctionComponent } from 'react';
import { HeaderButton } from '../header-button/header-button';
import { Link as RouterLink } from 'react-router-dom';
import UserMenu from '../user-menu/user-menu';

export interface HeaderProps {
  title?: string;
}

export const Header: FunctionComponent<HeaderProps> = ({ title }) => {
  return (
    <Box
      as="header"
      data-testid="Header"
      backgroundColor={useColorModeValue('gray.50', 'gray.900')}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
        maxW="7xl"
        py={{ base: '2', md: '4' }}
        px={{ base: '4', md: '8' }}
      >
        <Link as={RouterLink} to="/" d="flex">
          <Heading fontSize="xl" as="p">
            <FavIcon
              fontSize="20px"
              color={useColorModeValue('gray.800', 'gray.200')}
              mr={2}
            />
            {title}
          </Heading>
        </Link>

        <ButtonGroup variant="ghost" color="gray.600">
          <HeaderButton to="/">Events</HeaderButton>
          <HeaderButton to="/organizers">Organizers</HeaderButton>
          <ColorModeToggle />
          <UserMenu />
        </ButtonGroup>
      </Stack>
    </Box>
  );
};

export default Header;
