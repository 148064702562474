import { FunctionComponent, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const EventsPage = lazy(() => import('../events-page/events-page'));
const EventDetailPage = lazy(
  () => import('../event-detail-page/event-detail-page')
);

/* eslint-disable-next-line */
export interface EventsRouterProps {}

export const EventsRouter: FunctionComponent<EventsRouterProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <EventsPage />
      </Route>
      <Route path={`${path}/:id`}>
        <EventDetailPage />
      </Route>
    </Switch>
  );
};

export default EventsRouter;
