import { ObservableStatus, useFirestore, useFirestoreDocData } from 'reactfire';

import { Organizer } from '@larpcalendar/types';
import { doc } from '@firebase/firestore';
import { organizerConverter } from '../organizer-converter/organizer-converter';

export const useOrganizer = (id: string): ObservableStatus<Organizer> => {
  const firestore = useFirestore();
  const ref = doc(firestore, 'organizers', id).withConverter(
    organizerConverter
  );
  return useFirestoreDocData(ref);
};

export default useOrganizer;
