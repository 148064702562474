import {
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect } from 'react';

import { AlertBox } from '../alert-box/alert-box';
import { FaPaperPlane } from 'react-icons/fa';
import { sendEmailVerification } from '@firebase/auth';
import { useUser } from 'reactfire';

/* eslint-disable-next-line */
export interface SettingsVerifyEmailProps {}

export const SettingsVerifyEmail: FunctionComponent<SettingsVerifyEmailProps> =
  (_props) => {
    const { status, data: user } = useUser();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useBoolean();
    const toast = useToast();

    useEffect(() => {
      if (user && !user.emailVerified) {
        user.reload();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClick = async () => {
      try {
        setIsLoading.on();
        if (!user) {
          throw new Error('No user found!');
        }
        await sendEmailVerification(user);
        onOpen();
        setIsLoading.off();
      } catch (error) {
        console.error(error);
        setIsLoading.off();
        toast({
          title: 'Email Verification Failed',
          description:
            "We couldn't send a verification email, please try again",
          status: 'error',
        });
      }
    };
    return status === 'loading' ? (
      <Skeleton width="100%" height={10} />
    ) : (
      <HStack w="100%" spacing={0}>
        <AlertBox status={user?.emailVerified ? 'success' : 'error'} py={2}>
          {user?.emailVerified ? 'Email verified!' : 'Email not verified'}
        </AlertBox>
        {!user?.emailVerified && (
          <ButtonGroup>
            <Button
              onClick={onClick}
              isLoading={isLoading}
              rightIcon={<FaPaperPlane />}
              borderStartRadius={0}
            >
              Verify
            </Button>
          </ButtonGroup>
        )}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Email Verification Sent!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <Text>
                  We will shortly send you an email with a link to verify your
                  email address.
                </Text>
                <Text>
                  Please check your spam folder if you don't recieve the email.
                </Text>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    );
  };

export default SettingsVerifyEmail;
